<template>
  <v-main>
    <v-container grid-list-xs>
      <v-row align="center">
        <div v-if="disabled">
          <span> 你还不是Session Pro用户，不能使用高级功能。 </span>
          <v-dialog max-width="800px" transition="dialog-transition">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-2" color="primary" v-on="on" outlined small
                >联系我们购买</v-btn
              >
            </template>
            <v-card>
              <v-card-title primary-title>Session Pro</v-card-title>
              <v-card-text>
                <span> 请添加我们的QQ号详谈Pro合作事宜</span>
                <br />
                <v-img :src="require('../assets/munshareqq.jpg')"> </v-img>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <div v-else>
          {{ $t("Thanks") }}
        </div>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-card>
            <v-card-text>
              {{ $t("loginTip") }}
              <v-card
                elevation="0"
                class="d-flex flex-row ma-4"
                v-if="loggedIn"
              >
                <v-avatar size="60">
                  <img :src="orgAvatar" :alt="orgName" />
                </v-avatar>
                <v-badge
                  class="align-self-center"
                  right
                  color="primary"
                  v-if="this.$store.state.pro"
                >
                  <span slot="badge">Pro</span>
                  <div class="text-h6 ml-2">
                    {{ orgName }}
                  </div>
                </v-badge>
                <div class="text-h6 ml-2 align-self-center" v-else>
                  {{ orgName }}
                </div>
              </v-card>
              <v-btn color="primary" to="/login" v-else block>
                {{ $t("login") }}
              </v-btn>
            </v-card-text>
            <v-card-text>
              <saved-session></saved-session>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="6">
          <v-card>
            <v-card-title class="text-subtitle-2">
              {{ $t("showingBrand") }}
            </v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col md="10">
                  <v-text-field
                    :disabled="disabled"
                    outlined
                    dense
                    :label="this.$t('showConferenceName')"
                    v-model="conferenceName"
                  ></v-text-field>
                </v-col>
                <v-col md="1" class="ml-2">
                  <v-btn
                    color="secondary"
                    @click="setConferenceName"
                    :disabled="disabled"
                    >{{ $t("confirm") }}</v-btn
                  >
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-switch
                  v-model="showSession"
                  :disabled="disabled"
                  inset
                  :label="showSession ? this.$t('showPro') : this.$t('hidePro')"
                ></v-switch>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title class="text-subtitle-2">
              {{ $t("systemLanguage") }}
            </v-card-title>
            <v-card-text class="ma-0">
              <v-radio-group v-model="$root.$i18n.locale" :disabled="disabled">
                <div class="d-flex">
                  <v-radio value="zh"></v-radio>
                  <v-avatar size="20" tile>
                    <img :src="require('../assets/svg/zh.svg')" />
                  </v-avatar>
                  <span class="ml-1">中文</span>
                </div>
                <div class="d-flex">
                  <v-radio value="en"></v-radio
                  ><v-avatar size="20" tile>
                    <img :src="require('../assets/svg/en.svg')" /> </v-avatar
                  ><span class="ml-1">English</span>
                </div>
              </v-radio-group>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="ma-0">
              {{ $t("resetSession") }}
              <v-btn color="warning" block @click="restore">
                <v-icon>mdi-restore</v-icon></v-btn
              >
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title primary-title>{{ $t("setTheme") }} </v-card-title>
            <v-card-text class="ma-0">
              <v-switch
                v-model="$vuetify.theme.dark"
                :disabled="disabled"
                inset
                :label="this.$t('darkMode')"
                persistent-hint
              ></v-switch>
            </v-card-text>
            <v-card-text>
              {{ $t("themeColor") }}
              <v-color-picker v-model="color"></v-color-picker>
              <v-btn color="secondary" @click="setTheme" :disabled="disabled">
                {{ $t("confirm") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import db from "@/plugins/leancloud";
import SavedSession from "../components/savedSession.vue";
export default {
  name: "account",
  components: { SavedSession },
  metaInfo() {
    return {
      title: this.$t("account"),
    };
  },
  data() {
    return {
      type: "hex",
      hex: "#398961",
      conferenceName: "",
      motionComplete: false,
      motionData: {},
      motionTypeData: {},
      motionCountryData: {},
      motionTypeOptions: {
        legend: { display: false },
        title: {
          display: true,
          text: "动议类型",
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                // show integer ticks
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      },
      motionOptions: {
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                // show integer ticks
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    disabled() {
      if (this.$store.state.pro === false) {
        return true;
      } else {
        return false;
      }
    },
    showSession: {
      get() {
        return this.$store.state.currentSession.showSession;
      },
      set(v) {
        this.$store.commit("setShowSession", v);
      },
    },
    color: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },
    loggedIn() {
      let flag = false;
      if (this.$store.state.user) {
        flag = true;
      }
      return flag;
    },
    orgAvatar() {
      return this.$store.state.user && this.$store.state.user.avatar;
    },
    orgName() {
      return this.$store.state.user && this.$store.state.user.name_zh;
    },
    munid() {
      return `http://www.munshare.com/munid/` + this.$store.state.user.objectId;
    },
    motionNumber() {
      return this.$store.state.currentSession.motionHistory.length;
    },
  },
  methods: {
    restore() {
      var result = confirm(this.$t("resetConfirm"));
      if (result) {
        db.User.logOut();
        window.localStorage.removeItem("vuex");
        window.location.reload();
      }
    },

    setConferenceName() {
      this.$store.commit("setConferenceName", this.conferenceName);
    },
    setTheme() {
      this.$vuetify.theme.themes.light.primary = this.color;
      this.$vuetify.theme.themes.dark.primary = this.color;
      this.$store.commit("setTheme", this.color);
    },
  },
  created() {
    this.$store.dispatch("fetchConferences").then(() => {});
  },
};
</script>
<i18n>
{
  "en": {
    "account": "Account",
    "reset": "Reset",
    "resetConfirm":"Are you sure to clear data and restart?",
    "confirm": "Confirm",
    "about-session": "About Session",
    "confHistory": "History",
    "about-munshare": "About Munshare",
    "immediateUse":"Chair MUN Online",
    "login":"Please Login",
    "loginTip":"Login to save conference data",
    "munshare": "Munshare",
    "showPro": "Show Pro Logo",
    "hidePro": "Hide Pro Logo",
    "resetSession":"Clear the data and restore Session",
    "showConferenceName": "Display your conference name",
    "darkMode":"Dark Mode",
    "setTheme":"Set Theme",
    "themeColor":"Theme Color",
    "systemLanguage":"System Language",
    "showingBrand":"Conference Brand",
    "Thanks":"Thanks for your support"
  },
  "zh": {
    "account": "个人中心",
    "reset": "重置",
    "resetConfirm":"确定清除所有数据并重启App么？",
    "about-session": "关于模时Session",
    "confirm": "确定",
    "about-munshare": "关于模时",
    "showConferenceName": "你的会议名称",
    "showPro": "显示Pro Logo",
    "hidePro": "隐藏Pro Logo",
    "munshare": "模时",
    "immediateUse":"随开随用的模联议程",
    "resetSession":"重置Session本地数据，恢复原始状态。",
    "confHistory": "会议历史记录",
    "loginTip":"登录后会议数据自动保存；主持会议时无需网络连接",
    "login":"请登录",
    "setTheme":"主题设置",
    "darkMode":"夜间模式",
    "themeColor":"主题颜色",
    "showingBrand":"会议品牌展示",
    "systemLanguage":"系统和会议语言",
    "Thanks":"感谢你使用Session Pro。如有任何使用问题，请联系模时。"
  }
}
</i18n>
