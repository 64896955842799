<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ msg }}
    </v-snackbar>
    <v-row>
      <v-col md="6" v-for="(item, index) in confs" :key="index">
        <div>
          <v-badge color="primary" overlap :value="isCurrent(item)">
            <span slot="badge"><v-icon>mdi-progress-clock</v-icon></span>
            <v-card class="mx-2">
              <v-card-title primary-title>
                {{ item.committee }} <br />
                {{ item.agenda }}
              </v-card-title>
              <v-card-text>
                <p class="subtitle-2">
                  {{ item.inSessionCountries.length }}
                  {{ $t("nations") }}
                </p>
                {{ new Date(item.updatedAt).toLocaleString(["zh-CN"]) }}
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" v-if="isCurrent(item)" to="/setup">
                  {{ $t("continueChair") }}
                </v-btn>
                <v-btn
                  color="secondary"
                  @click="chairConf(item)"
                  v-else
                  outlined
                >
                  {{ $t("switchChair") }}
                </v-btn>
                <span v-if="isCurrent(item)">
                  <v-menu close-on-click offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="secondary"
                        class="ml-1"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense rounded>
                      <v-list-item>
                        <v-btn color="secondary" @click="toHistory(item)">
                          {{ $t("checkHistory") }}
                        </v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn color="secondary" @click="toMPC(item)">
                          {{ $t("MPC") }}
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>

                <v-btn color="error" @click="deleteConf(item)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-badge>
        </div>
      </v-col>
      <v-col v-if="confs.length === 0">尚未主持过会议。</v-col>
      <v-col v-if="notLoggedIn" class="text-subtitle-2">请登录后新增会议</v-col>
      <v-btn
        color="secondary"
        block
        @click="newConf"
        class="mt-2"
        :disabled="loading || notLoggedIn"
        :loading="loading"
      >
        {{ $t("newChair") }}
      </v-btn>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      snackbar: false,
      msg: "",
    };
  },
  watch: {
    search(val) {
      this.filterOutSessionCountries(val);
    },
    outSessionCountries(val) {
      this.outSessionCountriesFiltered = val;
    },
    inSessionCountriesEmpty: {
      immediate: true,
      handler(inSessionCountriesEmpty) {
        if (inSessionCountriesEmpty) {
          this.$store.commit("initializeOoutSessionCountries");
        }
      },
    },
  },
  computed: {
    notLoggedIn() {
      let flag = true;
      if (this.$store.state.user) {
        flag = false;
      }
      return flag;
    },
    confs() {
      return JSON.parse(JSON.stringify(this.$store.state.confsStored));
    },
  },
  methods: {
    isCurrent(item) {
      let flag = false;
      const id = this.$store.state.currentConfId;
      if (item.objectId == id) {
        flag = true;
      }
      return flag;
    },
    chairConf(item) {
      this.$store.commit("setConfId", item.objectId);
      this.$store.commit("setCurrentSession", item);
      this.$router.push("/setup");
    },
    toHistory(item) {
      this.$store.commit("setConfId", item.objectId);
      this.$store.commit("setCurrentSession", item);
      this.$router.push("/history");
    },
    toMPC(item) {
      this.$store.commit("setConfId", item.objectId);
      this.$store.commit("setCurrentSession", item);
      this.$router.push("/mpc");
    },
    deleteConf(item) {
      var result = confirm(this.$t("confirmDelete"));
      if (result) {
        this.$store.dispatch("deleteConf", item.objectId);
      }
    },
    newConf() {
      this.loading = true;
      this.$store
        .dispatch("newConf")
        .then(() => {
          this.loading = false;
          this.$router.push("/setup");
        })
        .catch((err) => {
          this.snackbar = true;
          this.msg = err + "请检查网络连接";
        });
    },
  },
  created() {
    this.committeeName = this.$store.state.currentSession.committee;
    // this.$store.commit("initializeoutSessionCountries");
  },
};
</script>

<i18n>
{
  "en": {
    "new": "New Committee",
    "MPC": "MPC",
    "template": "Template Committee",
    "confirmDelete":"Are you sure to delete this conference?",
    "templateIntro": "The template committee can change over time. You can customize countries based on it",
    "customize": "Customize Country",
    "checkHistory": "Record",
    "name-en": "Name",
    "select-country": "Select Country",
    "your-committee": "Your Committee",
    "committee-name": "Committee Name",
    "countries": "Countries",
    "save": "Save",
    "mod": "Mod",
    "add-countries": "Please Add Countries",
    "cancel-all": "Clear All",
    "confirm": "Confirm",
    "continueChair": "Continue",
    "newChair": "New Conference",
    "switchChair": "Switch",
    "nations": "Nations",
    "start": "Start"
  },
  "zh": {
    "new": "设置新委员会",
    "MPC": "MPC设置",
    "confirmDelete":"确定删除该场会议？",
    "checkHistory": "会议记录",
    "template": "选择预设委员会",
    "templateIntro": "预设委员会可能会随时间变动。可以在此基础上添加删除国家",
    "customize": "自定义国家",
    "name-en": "英文名",
    "select-country": "选择国家",
    "your-committee": "你的委员会",
    "committee-name": "委员会名称",
    "save": "保存",
    "countries": "个国家",
    "add-countries": "请添加委员会国家",
    "cancel-all": "全部取消",
    "confirm": "确认",
    "continueChair": "继续主持",
    "switchChair": "切换会议",
    "newChair": "新增会议",
    "nations": "个国家",
    "start": "开始"
  }
}
</i18n>
